<template>
	<div class="dropdown">
		<!-- <label class="country-label" title="Click to display country list." for="country" @click="showOptions()">
			{{ languageStrings.countryWhereRegistered }}
		</label> -->
		<input
			id="country"
			class="dropdown-input curved-border"
			name="country"
			@click="showOptions()"
			@focus="showOptions()"
			@blur="exit()"
			@keyup="keyWatcher($event)"
			v-model="searchFilter"
			:disabled="disabled"
			:placeholder="setCountryDropdownPlaceholder()"
		/>
		<div class="dropdown-content" v-show="optionsShown">
			<div v-if="defaultCountries && !searchFilter">
				<span class="default-label"> {{ languageStrings.countryCode }} {{ systemSettings.defaultPhoneCountryCode }}</span>
				<div class="dropdown-item" v-for="(country, index) in defaultCountries" :key="index" @mousedown="selectOption(country)">
					<img
						class="country-flag"
						v-if="hasFlag(country.countryCode)"
						:src="require(`country-flag-icons/flags/3x2/${country.countryCode}.svg`)"
						:alt="`flag-${country.countryCode}`"
						width="40"
					/>
					<span>{{ country.name }}</span>
				</div>
				<hr />
			</div>
			<div v-if="filteredOptions?.length > 0">
				<div class="dropdown-item" v-for="(country, index) in filteredOptions" :key="index" @mousedown="selectOption(country)">
					<img
						class="country-flag"
						v-if="hasFlag(country.countryCode)"
						:src="require(`country-flag-icons/flags/3x2/${country.countryCode}.svg`)"
						:alt="`flag-${country.countryCode}`"
						width="40"
					/>
					<span>{{ country.name }}</span>
				</div>
			</div>
			<div v-else class="no-matches">{{ languageStrings.noMatches }}</div>
		</div>
	</div>
</template>

<script>
// https://www.npmjs.com/package/country-flag-icons
import { hasFlag } from "country-flag-icons";

export default {
	name: "DropdownMenu",
	props: {
		countryList: Array,
		playerState: Object,
		systemSettings: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
		countryDropdownType: String,
	},
	data() {
		return {
			selected: {},
			defaultCountries: this.countryList.filter((country) => country?.countryPrefix === this.systemSettings?.defaultPhoneCountryCode) || [],
			searchFilter: "",
			filteredOptions: [],
			name: "",
			disabled: false,
			optionsShown: false,
			country: "",
			flag: null,
			hasFlag: hasFlag,
		};
	},
	watch: {
		searchFilter() {
			this.filterOptions();
		},
	},
	mounted() {
		this.filterOptions();
		this.selected = this.countryList.filter((country) => country.name === this.country.name)[0] || {};
		// this.selectOption(this.selected);
	},
	methods: {
		setCountryDropdownPlaceholder() {
			let placeholder;
			if (this.countryDropdownType === "FirstAndLastName") placeholder = this.languageStrings.countryOfResidence;
			if (this.countryDropdownType === "Nationality") placeholder = this.languageStrings.nationality;
			if (this.countryDropdownType === "PhoneNumber") placeholder = this.languageStrings.typeFilterCountryList;
			return placeholder;
		},
		selectOption(country) {
			this.selected = country;
			this.selected.countryDropdownType = this.countryDropdownType;
			let state = this.playerState;
			state.playersCountry = country;
			this.optionsShown = false;
			this.searchFilter = this.selected.name;
			this.eventBus.emit("updatePlayerState", state);
			this.eventBus.emit("countrySelected", this.selected);
		},
		showOptions() {
			if (!this.disabled) {
				this.searchFilter = "";
				this.optionsShown = true;
			}
		},
		exit() {
			if (!this.selected?.name) {
				this.selected = {};
				this.searchFilter = "";
			} else {
				this.searchFilter = this.selected.name;
				this.selectOption(this.selected);
			}
			this.optionsShown = false;
		},
		keyWatcher(e) {
			if (e.key === "Enter" && this.filteredOptions[0]) this.selectOption(this.filteredOptions[0]);
		},
		filterOptions() {
			this.filteredOptions = [];
			if (this.searchFilter?.length === 0) {
				this.filteredOptions = this.countryList;
				return;
			}

			let regOption = new RegExp(this.searchFilter, "ig");
			for (const country of this.countryList) {
				if (country.name.match(regOption)) this.filteredOptions.push(country);
			}
			return;
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.dropdown {
	position: relative;
	display: block;
	margin: auto;
	width: 100%;
}

.dropdown-input {
	background: #fff;
	border: 1px solid #e7ecf5;
	border-radius: 3px;
	color: #333;
	display: block;
	width: calc(100% - 15px);
}

.dropdown-input:hover {
	background: #f8f8fa;
}

.dropdown-content {
	position: absolute;
	background-color: #cdcdcd;
	/* min-width: 248px;
	max-width: 248px; */
	width: 100%;
	max-height: 250px;
	border: 1px solid #e7ecf5;
	box-shadow: 0px -8px 34px 0px rgba(0, 0, 0, 0.05);
	overflow: auto;
	z-index: 1;
}

.dropdown-item,
.no-matches {
	display: flex;
	align-items: center;
	color: black;
	line-height: 1em;
	padding: 8px;
	text-decoration: none;
	cursor: pointer;
}

.country-label {
	cursor: pointer;
}

.dropdown-item * {
	margin-right: 5px;
}

/* .dropdown > * {
	width: 95%;
} */

.default-label {
	display: block;
	color: black;
	text-align: center;
	font-weight: bold;
}

hr {
	background-color: #000;
}

.no-matches {
	cursor: not-allowed;
}

.dropdown-item:hover {
	background-color: #5a8fef;
}

.dropdown:hover .dropdowncontent {
	display: block;
}

.dropdown-input {
	font-size: 1.5em;
	border-radius: 12px;
	color: #6305C9;
}

.curved-border {
	height: 3.5vh;
}

/* @media (min-width: 768px) {
	.dropdown-input {
		padding: 2px 7px;
	}
} */
</style>
